import { render, staticRenderFns } from "./SoundKits.vue?vue&type=template&id=14b2ce8c&lang=pug&"
import script from "./SoundKits.vue?vue&type=script&lang=ts&"
export * from "./SoundKits.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports