import { get, post, update, remove } from '~/services/http-requests'

const _BS_SOUND_KITS = 'sound_kits'

/*
 **** GET ****
 */

function fetchAllSoundKits(): Promise<ISoundKit[]> {
    return get({ baseProperty: _BS_SOUND_KITS })
}

async function fetchSingleSoundKit(soundKitId: ISoundKit['id']): Promise<ISoundKit> {
    const [first] = await get({ baseProperty: _BS_SOUND_KITS, fullUrl: `${_BS_SOUND_KITS}.json?id=${soundKitId}` })
    return first
}

/*
 **** POST ****
 */
function createSoundKit(soundKit: ISoundKit): Promise<ISoundKit> {
    return post({ baseProperty: _BS_SOUND_KITS, dispatchParams: soundKit })
}

/*
 **** PATCH ****
 */
function updateSoundKit(soundKit: Partial<ISoundKit>): Promise<ISoundKit> {
    return update({ baseProperty: _BS_SOUND_KITS, fullUrl: `${_BS_SOUND_KITS}/${soundKit.id}/`, dispatchParams: soundKit })
}

/*
 **** DELETE ****
 */
function removeSoundKit(soundKitId: ISoundKit['id']) {
    return remove({ baseProperty: _BS_SOUND_KITS, fullUrl: `${_BS_SOUND_KITS}/${soundKitId}/` })
}

export { fetchAllSoundKits, fetchSingleSoundKit, createSoundKit, updateSoundKit, removeSoundKit }
