





































import { Vue, Component, Mixins } from 'vue-property-decorator'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { ModalStore, IModalParams } from '~/store/modal'
import { fetchAllSoundKits } from '#/api/sound-kits'
import { ILimitOffsetParams } from '~/interfaces/interface-utils'
import { CleanDescription } from '#/mixins/clean-description'
import { UserStore } from '#/store/user'
import AlertBoxSubscription from '#/components/AlertBoxSubscription.vue'

@Component({ components: { VLoadSpinner, AlertBoxSubscription } })
export default class SoundKitsPage extends Mixins(CleanDescription) {
    isLoading = true
    soundKits: ISoundKit[] = []

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
    @UserStore.Getter('hasEliteSubscription') hasEliteSubscription: boolean

    async _fetchAllSoundKits() {
        this.isLoading = true
        try {
            this.soundKits = await fetchAllSoundKits()
        } finally {
            this.isLoading = false
        }
    }

    created() {
        this._fetchAllSoundKits()
    }
}
